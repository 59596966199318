import React, { useContext } from "react";

import { HomeInfoContext } from "./HomeInfoContext";

export const HomeInfoValidationErrors = () => {
  const {
    homeInfo: { validation_errors }
  } = useContext(HomeInfoContext);

  if (!validation_errors || validation_errors.length === 0) {
    return null;
  }

  return (
    <div className="alert alert-danger">
      <p className="mb-2">
        <strong>Following errors needs to be fixed before any updates:</strong>
      </p>
      <ul className="my-0">
        {validation_errors.map((error) => (
          <li key={error}>- {error}</li>
        ))}
      </ul>
    </div>
  );
};
