import { MultiSelect } from "@mantine/core";
import { Formik, Form } from "formik";
import { isEqual } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import * as yup from "yup";

import api from "../../../common/coreApiClient";
import { CustomField } from "../../../common/CustomField";
import { LoadingSpinner } from "../../../common/LoadingSpinner";
import { MantineProvider } from "../../../common/MantineProvider";
import { showSuccessToast } from "../../../common/modals";
import { RailsErrors } from "../../../common/RailsErrors";

const createOptions = (cleanersList) =>
  cleanersList.map((cleaner) => ({ value: cleaner.id, label: cleaner.name }));
const getPreferredCleaners = (cleanersList) =>
  cleanersList.flatMap((cleaner) => (cleaner.preferred ? [cleaner.id] : []));

const PREFERRED_CLEANERS_URL = "/v1/internal/cleaners/preferred";

const PUT_ERROR_MESSAGE = "Operation failed, please try again later.";
const GET_ERROR_MESSAGE = "Could not fetch data, please try again later.";
const PUT_SUCCESS_MESSAGE = "Preferred Cleaners List updated.";

const validationSchema = yup.object().shape({
  cleanersIds: yup.array().of(yup.string())
});

const PreferredCleanersForm = ({ property, operator }) => {
  const [cleanersList, setCleanersList] = useState([]);
  const [savedValues, setSavedValues] = useState({
    cleanersIds: []
  });
  const [isFetching, setIsFetching] = useState(false);
  const [serverErrors, setServerErrors] = useState([]);

  useEffect(() => {
    const fetchCleanersList = async () => {
      setIsFetching(true);

      try {
        const res = await api.get(PREFERRED_CLEANERS_URL, {
          property_id: property.id
        });

        setSavedValues({ cleanersIds: getPreferredCleaners(res) });
        setCleanersList(createOptions(res));
      } catch (error) {
        setServerErrors([GET_ERROR_MESSAGE]);
      } finally {
        setIsFetching(false);
      }
    };

    fetchCleanersList();
  }, [property.id]);

  const handleSubmit = useCallback(
    async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        await api.put(PREFERRED_CLEANERS_URL, null, {
          property_id: property.id,
          operator_id: operator.id,
          preferred_cleaners: values.cleanersIds
        });

        setSavedValues({ cleanersIds: values.cleanersIds });
        showSuccessToast(PUT_SUCCESS_MESSAGE);
      } catch (error) {
        setServerErrors([PUT_ERROR_MESSAGE]);
      } finally {
        setSubmitting(false);
      }
    },
    [property.id, operator.id]
  );

  if (isFetching) return <LoadingSpinner message="" />;

  return (
    <div className="twbs">
      <h2 className="mb-4">Preferred cleaners list</h2>
      {cleanersList.length > 0 ? (
        <Formik
          initialValues={savedValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setValues, values }) => (
            <Form>
              <RailsErrors errors={serverErrors} />
              <div className="mb-4">
                <MantineProvider>
                  <CustomField
                    name="cleanersIds"
                    as={MultiSelect}
                    placeholder="The property has no preferred cleaners - select cleaners"
                    data={cleanersList}
                    searchable
                  />
                </MantineProvider>
              </div>
              {!isEqual(
                values.cleanersIds.sort(),
                savedValues.cleanersIds.sort()
              ) && (
                <div className="d-flex">
                  <button
                    type="button"
                    className="btn btn-primary btn-primary--full-width mr-3"
                    disabled={isSubmitting}
                    onClick={() => setValues(savedValues)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-submit btn-submit--full-width"
                    disabled={isSubmitting}
                  >
                    Save
                  </button>
                </div>
              )}
            </Form>
          )}
        </Formik>
      ) : (
        <p>No cleaners available for this locality.</p>
      )}
    </div>
  );
};

export default PreferredCleanersForm;
