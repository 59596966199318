import { useFormikContext } from "formik";
import React, { useContext } from "react";

import { FormGroup as FormGroupWithoutPreview } from "../../../../common/forms/FormGroup";
import GuestySVG from "../../../../images/guesty.svg";
import { HomeInfoContext } from "../HomeInfoContext";
import FieldIcon from "./FieldIcon";

const VisibleToGuests = () => (
  <FieldIcon
    icon={<i className="fa fa-eye" />}
    tooltipText="Visible to guests"
  />
);

export const AirbnbSyncIcon = () => (
  <FieldIcon
    icon={<i className="fab fa-airbnb" />}
    tooltipText="In sync with Airbnb listing"
  />
);

export const GuestySyncIcon = () => (
  <FieldIcon
    icon={
      <img
        className="guesty-sync-logo"
        src={GuestySVG}
        alt="In sync with Guesty listing"
      />
    }
    tooltipText="In sync with Guesty listing"
  />
);

const VisibleToCleaners = () => (
  <FieldIcon
    icon={<i className="fa fa-broom" />}
    tooltipText="Displayed in the Housekeeping App"
  />
);

export const Description = ({ text }) => (
  <FieldIcon icon={<i className="fa fa-info-circle" />} tooltipText={text} />
);

export const FormGroup = ({
  afterOnChange,
  name,
  airbnbSync,
  visibleToGuests,
  visibleToCleaners,
  description,
  instructions = null,
  characterCount = null,
  ...props
}) => {
  const { handleChange } = useFormikContext();
  const { setIsModified } = useContext(HomeInfoContext);

  const onChange = (event) => {
    handleChange(event);
    if (afterOnChange) {
      afterOnChange(event);
    } else {
      setIsModified(true);
    }
  };
  return (
    <div className="field-group">
      <FormGroupWithoutPreview
        name={name}
        onChange={onChange}
        labelFlags={[
          visibleToGuests && <VisibleToGuests key="visibleToGuests" />,
          airbnbSync && <AirbnbSyncIcon key="airbnbSync" />,
          visibleToCleaners && <VisibleToCleaners key="visibleToCleaners" />
        ].filter(Boolean)}
        description={description ? <Description text={description} /> : null}
        characterCount={characterCount}
        {...props}
      />
      {instructions && <div className="field-instructions">{instructions}</div>}
    </div>
  );
};
