import { useEffect, useState } from "react";

import { fetchGuestyListingData } from "./api";

export const useGuestyData = (propertyId) => {
  const [guestyListingData, setGuestyListingData] = useState({});
  const [guestyDataLoading, setGuestyDataLoading] = useState(false);

  const fetchGuestyData = async (id) => {
    setGuestyDataLoading(true);

    try {
      const data = await fetchGuestyListingData(id);
      setGuestyListingData(data);
    } catch {
      setGuestyListingData({});
    }

    setGuestyDataLoading(false);
  };

  useEffect(() => {
    if (!propertyId) return;

    fetchGuestyData(propertyId);
  }, [propertyId]);

  return { guestyListingData, guestyDataLoading };
};
