import React, { useContext } from "react";

import { formatAmountWithCurrency } from "../../../common/numbers";
import { HomeInfoContext } from "./HomeInfoContext";

export const PreviewScreen = () => {
  const { homeInfo } = useContext(HomeInfoContext);

  const currentTimeStampSeconds = Math.floor(Date.now() / 1000);
  const guestAppToken = window.btoa(
    `property_id=${homeInfo.property_id}&timestamp=${currentTimeStampSeconds}`
  );
  const url = `${window.AirbaseConstants.GUEST_APP_URL}/p/${guestAppToken}`;

  const penaltyChargeNotice =
    homeInfo.locality && homeInfo.locality.guest_penalty_charge
      ? `A minimum fee of ${formatAmountWithCurrency(
          homeInfo.locality.guest_penalty_charge,
          homeInfo.property_attributes.currency_symbol
        )} will be charged for lockouts, late check outs or failure to leave the keys in the lockbox/keynest`
      : "Late checkouts will be charged a fee";

  return (
    <div className="onboarding-form">
      <div className="onboarding-preview">
        <h2 className="w-100">Preview</h2>
      </div>
      <a
        className="btn btn-primary w-50"
        href={url}
        target="_blank"
        rel="noreferrer"
      >
        Open preview in Guest App
      </a>

      <div className="house-rules-preview">
        <h2 className="w-100">House rules</h2>
        <p className="house-rules-preview__content">Smoking is not allowed</p>
        <p className="house-rules-preview__content">
          No music or loud noise after 10:00pm
        </p>
        <p className="house-rules-preview__content">{penaltyChargeNotice}</p>
        <p className="house-rules-preview__content">
          In the event of a party or a gathering, you will be charged for any
          costs related to damages and additional cleaning
        </p>

        {homeInfo?.house_rules && (
          <p className="house-rules-preview__content">{homeInfo.house_rules}</p>
        )}
      </div>
    </div>
  );
};
