import { useContext, useMemo } from "react";

import { guestyMissingFields } from "./helpers";
import { HomeInfoContext } from "./HomeInfoContext";

export const useGuestySync = () => {
  const {
    homeInfo,
    guestyListingData: { sync_error }
  } = useContext(HomeInfoContext);

  const {
    property_attributes: { multiplat_active, multiplat_synced_at }
  } = homeInfo;

  const missingFields = useMemo(() => guestyMissingFields(homeInfo), [
    homeInfo
  ]);

  return {
    active: multiplat_active,
    syncedAt: multiplat_synced_at,
    missingFields,
    error: sync_error
  };
};
