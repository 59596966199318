import { BEDS, DESCRIPTION, INTEGRATION, LOCATION, SIZE } from "./sections";

export const GUESTY_SYNC_REQUIRED_FIELDS = {
  [LOCATION]: [
    "property_attributes.street",
    "property_attributes.city",
    "property_attributes.postcode",
    "property_attributes.country"
  ],
  [INTEGRATION]: ["host_preferences_attributes.multiplat_listed"],
  [DESCRIPTION]: ["listing_title", "listing_summary"],
  [SIZE]: ["property_attributes.accommodates", "property_attributes.bathrooms"],
  [BEDS]: ["property_attributes.bedrooms", "beds_attributes"]
};
