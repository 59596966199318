import { useContext, useMemo } from "react";

import { hasAirbnbRequiredFields } from "./helpers";
import { HomeInfoContext } from "./HomeInfoContext";
import { ListingPhotosContext } from "./ListingPhotosContext";

export const useAirbnbSync = () => {
  const {
    homeInfo,
    airbnbListingData: { sync_error }
  } = useContext(HomeInfoContext);
  const { numberOfAirbnbPhotos } = useContext(ListingPhotosContext);

  const hasRequiredFields = useMemo(
    () => hasAirbnbRequiredFields(numberOfAirbnbPhotos, homeInfo),
    [homeInfo, numberOfAirbnbPhotos]
  );

  const {
    property_attributes: {
      airbnb_synced_at,
      listing_rejected_status_notes,
      listing_approval_status_approved
    }
  } = homeInfo;

  const syncErrors = useMemo(() => {
    if (sync_error) {
      const sync_error_obj = JSON.parse(sync_error);
      const { error_message } = sync_error_obj;
      return { status: "error", message: error_message };
    }

    if (!listing_approval_status_approved && listing_rejected_status_notes) {
      return { status: "rejected", message: listing_rejected_status_notes };
    }

    return null;
  }, [
    sync_error,
    listing_rejected_status_notes,
    listing_approval_status_approved
  ]);

  return {
    hasRequiredFields,
    syncedAt: airbnb_synced_at,
    syncErrors
  };
};
