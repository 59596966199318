export const formatHours = (durationInHours) => {
  const duration = durationInHours * 60;

  const hours = Math.floor(duration / 60);
  const minutes = Math.floor(duration % 60);

  return `${hours} h. ${minutes < 10 ? `0${minutes}` : minutes} min.`;
};

export const toLocalFromUTC = (utcDateString) => {
  const utcDate = new Date(utcDateString);
  return new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000);
};
