export const GUESTY_MIN_SIZE = {
  width: 1920,
  height: 1080
};

export const guestyValidator = (file) => {
  if (!file.type.match("image/*") || !file.image) {
    return null;
  }

  if (file.image.height > file.image.width) {
    return {
      code: "portrait_not_allowed",
      message: "Portrait images are not allowed."
    };
  }

  if (
    file.image.width < GUESTY_MIN_SIZE.width ||
    file.image.height < GUESTY_MIN_SIZE.height
  ) {
    return {
      code: "min_resolution",
      message: `Minimum resolution is ${GUESTY_MIN_SIZE.width}x${GUESTY_MIN_SIZE.height}.`
    };
  }

  return null;
};
