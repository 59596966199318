import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { SelectOptions } from "../../../../common/SelectOptions";
import { FOLDERS } from "../constants";
import {
  selectCurrentFolderName,
  selectedFilesIds as selectedFilesIdsSelector
} from "../data/files/selectors";
import { updateFiles } from "../data/files/thunks";
import { BulkDeleteButton } from "./BulkDeleteButton";

const { FOLDER_NAME_AIRBNB } = window.AirbaseConstants.PropertyFile;

export const BulkActionsMoveForm = () => {
  const dispatch = useDispatch();
  const [moveToFolder, setMoveToFolder] = useState("");
  const [formVisible, setFormVisible] = useState(false);
  const currentFolderName = useSelector(selectCurrentFolderName);
  const selectedFilesIds = useSelector(selectedFilesIdsSelector);

  useEffect(() => {
    if (selectedFilesIds.length === 0) {
      setMoveToFolder("");
      setFormVisible(false);
    }
  }, [selectedFilesIds]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const newOrder = selectedFilesIds.map((id) => ({
        id,
        values: { folder_name: moveToFolder }
      }));

      await dispatch(updateFiles(newOrder));

      if (moveToFolder !== FOLDER_NAME_AIRBNB) {
        const updatedFiles = selectedFilesIds.map((id) => ({
          id,
          values: { airbnb_photo_id: null }
        }));

        await dispatch(updateFiles(updatedFiles));
      }
    },
    [dispatch, moveToFolder, selectedFilesIds]
  );

  const folderOptions = useMemo(
    () =>
      FOLDERS.filter(
        ({ name }) => name !== currentFolderName
      ).map(({ name }) => [name, name]),
    [currentFolderName]
  );

  if (formVisible) {
    return (
      <form onSubmit={handleSubmit}>
        <div className="form-inline ml-3">
          <div className="form-group">
            <label htmlFor="directory-select">Choose folder:</label>
            <select
              className="form-control ml-1"
              id="directory-select"
              onChange={(e) => {
                setMoveToFolder(e.target.value);
              }}
            >
              <SelectOptions includeBlank options={folderOptions} />
            </select>
          </div>
          <div className="form-group">
            <button
              disabled={!moveToFolder}
              type="submit"
              className="btn btn-primary ml-2"
            >
              Submit
            </button>
            <button
              type="button"
              className="btn btn-secondary ml-2"
              onClick={() => setFormVisible(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    );
  }

  return (
    <>
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => setFormVisible(true)}
      >
        <i className="fa fa-folder-open" aria-hidden="true" /> Move
      </button>

      <BulkDeleteButton />
    </>
  );
};
