import { useField } from "formik";
import React from "react";

export const RadioToggle = ({
  name,
  label,
  onLabel,
  onDescription,
  offLabel,
  offDescription
}) => {
  // eslint-disable-next-line no-unused-vars
  const [{ value }, _meta, { setValue }] = useField(name);

  return (
    <div className="form-row border rounded p-3">
      <div className="col-md-4">
        <h6>{label}</h6>
        <div className="form-check">
          <input
            type="radio"
            className="form-check-input"
            id={`${name}_true`}
            name={name}
            value="1"
            checked={value === true}
            onChange={() => {
              setValue(true);
            }}
          />
        </div>
        <div className="listing-status">
          <label className="form-check-label" htmlFor={`${name}_true`}>
            <span className="dot-green" /> {onLabel}
            <p className="desc">{onDescription}</p>
          </label>
        </div>

        <div className="form-check">
          <input
            type="radio"
            className="form-check-input"
            id={`${name}_false`}
            name={name}
            value="0"
            checked={value === false}
            onChange={() => {
              setValue(false);
            }}
          />
        </div>
        <div className="listing-status">
          <label className="form-check-label" htmlFor={`${name}_false`}>
            <span className="dot-red" /> {offLabel}
            <p className="desc">{offDescription}</p>
          </label>
        </div>
      </div>
    </div>
  );
};
