import "./NewCleanDemandForm.scss";
import { format } from "date-fns";
import { Formik, Form } from "formik";
import React, { useState, useMemo } from "react";
import * as yup from "yup";

import { useApiClient } from "../../../common/apiClient";
import { Explanation } from "../../../common/Explanation";
import { FormGroup } from "../../../common/forms/FormGroup";
import { RailsErrors } from "../../../common/RailsErrors";
import { SelectOptions } from "../../../common/SelectOptions";
import { showConfirmationModal } from "../../../common/showConfirmationModal";
import CleanerSelection from "../../cleaners/CleanerSelection";
import {
  SOURCE_EXPLANATION,
  CLEAN_TYPE_EXPLANATION,
  ATTRIBUTIONS_EXPLANATION
} from "./clean_demand_explanations";
import { formatDateTime } from "./helpers";

const {
  CLEAN_TYPES,
  SOURCES,
  ATTRIBUTIONS,
  ATTRIBUTION_HOST,
  CLEAN_TYPES_ALLOWED_CUSTOM_ATTRIBUTION
} = window.AirbaseConstants.CleanDemand;

const validationSchema = yup.object().shape({
  attribution: yup.string().required("Please select attribution"),
  clean_date: yup.date().required("Please set date"),
  duration: yup.number().required("Please set clean duration"),
  source: yup.string().required("Please select source"),
  clean_type: yup.string().required("Please select clean type"),
  flat_fee: yup.number(),
  skip_laundry: yup.boolean(),
  notes: yup.string()
});

const LAST_MINUTE_CLEAN_MESSAGE =
  "You are creating a last-minute clean demand with laundry required. You must ensure that laundry delivery/collection is arranged with the relevant supplier.";
const IS_SKIP_AUTO_CLEAN_MESSAGE =
  "This property has turned off auto-scheduling of cleans. This usually means that the host prefers to do their own cleaning. Please check to ensure a clean should be scheduled regardless.";

export const NewCleanDemandForm = ({
  cleanDemand,
  propertyId,
  bookingId,
  localityId,
  selectedCleanerJSON,
  cleanTime,
  isSkipAutoClean
}) => {
  const [serverErrors, setServerErrors] = useState([]);
  const [showCleanerSelect, setShowCleanerSelect] = useState(false);
  const demandDateTime = useMemo(
    () => formatDateTime(cleanDemand.clean_date, cleanTime),
    [cleanDemand, cleanTime]
  );
  const isEditMode = Boolean(cleanDemand.id);

  const apiClient = useApiClient();

  const handleSubmit = async (
    { specialist, redirectToEdit, ...values },
    { setSubmitting }
  ) => {
    const isLastMinuteCleanDemand =
      new Date(values.clean_date).toDateString() === new Date().toDateString();

    if (!values.skip_laundry && isLastMinuteCleanDemand) {
      const lastMinuteCleanConformation = await showConfirmationModal({
        message: LAST_MINUTE_CLEAN_MESSAGE
      });

      if (!lastMinuteCleanConformation) {
        return;
      }
    }

    if (isSkipAutoClean) {
      const isSkipAutoCleanConformation = await showConfirmationModal({
        message: IS_SKIP_AUTO_CLEAN_MESSAGE
      });

      if (!isSkipAutoCleanConformation) {
        return;
      }
    }

    setSubmitting(true);
    const splitDateTime = values.clean_date.split("T");

    const commonValues = {
      ...values,
      clean_date: splitDateTime[0],
      clean_time: splitDateTime[1]
    };

    try {
      if (cleanDemand.id) {
        await apiClient.patch(`/api/clean_demands/${cleanDemand.id}`, {
          clean_demand: commonValues
        });

        window.location.href = `/properties/${propertyId}/clean_demands/${cleanDemand.id}`;
      } else {
        const { data } = await apiClient.post(`/api/clean_demands`, {
          clean_demand: {
            ...commonValues,
            property_id: propertyId,
            booking_id: bookingId,
            cleans_attributes: [
              { cleaner_id: values.cleaner_id, start: values.clean_date }
            ]
          }
        });
        window.location.href = `/properties/${propertyId}/clean_demands/${data.id}`;
      }
    } catch (e) {
      if (e.response?.data?.errors) {
        setServerErrors(e.response?.data?.errors);
      }
    } finally {
      setSubmitting(false);
    }
  };

  const initialValues = {
    clean_date: demandDateTime || "",
    duration: cleanDemand.duration || "",
    source: cleanDemand.source || "internal",
    clean_type: cleanDemand.clean_type || "",
    flat_fee: cleanDemand.flat_fee || "",
    skip_laundry: cleanDemand.skip_laundry || false,
    notes: cleanDemand.notes || "",
    cleaner_id: "",
    attribution: cleanDemand.attribution || ATTRIBUTION_HOST
  };

  return (
    <div className="twbs neo_design clean-demand">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <Form>
            <RailsErrors errors={serverErrors} />
            <FormGroup
              label="Clean date and time"
              name="clean_date"
              type="datetime-local"
              min="2019-09-01T00:00"
              value={
                values.clean_date
                  ? format(new Date(values.clean_date), "yyyy-MM-dd'T'HH:mm")
                  : ""
              }
            />

            <div className="demand-details">
              <FormGroup
                label="Duration"
                name="duration"
                type="number"
                min={0}
                step="any"
              />
              <Explanation text="Standard clean duration based on property setup" />
            </div>

            {isEditMode && (
              <div className="twbs">
                <div className="alert alert-danger">
                  Editing demand date, time or duration will cancel already
                  scheduled or requested cleans!
                </div>
              </div>
            )}

            <div className="demand-details">
              <FormGroup label="Source" name="source" component="select">
                <SelectOptions options={SOURCES} />
              </FormGroup>
              <Explanation text={SOURCE_EXPLANATION[values.source]} visible />
            </div>

            <div className="demand-details">
              <FormGroup
                name="clean_type"
                label="Clean type"
                component="select"
              >
                <SelectOptions options={CLEAN_TYPES} />
              </FormGroup>

              <Explanation text={CLEAN_TYPE_EXPLANATION[values.clean_type]} />
            </div>

            {CLEAN_TYPES_ALLOWED_CUSTOM_ATTRIBUTION.includes(
              values.clean_type
            ) && (
              <div className="demand-details">
                <FormGroup
                  name="attribution"
                  label="Attribution"
                  component="select"
                >
                  <SelectOptions options={ATTRIBUTIONS} />
                </FormGroup>

                <Explanation
                  text={ATTRIBUTIONS_EXPLANATION[values.attribution]}
                />
              </div>
            )}

            <div className="demand-details">
              <FormGroup
                label="Flat fee (net)"
                name="flat_fee"
                type="number"
                placeholder="Leave empty to apply standard compensation"
              />
              <Explanation text="Flat fee is an override of hourly rate. If set, we completely disregard duration of clean or rate, we just pay out a flat amount to cleaner." />
            </div>

            {isEditMode && (
              <div className="twbs">
                <div className="alert alert-warning">
                  New flat fee is not going to be applied to the existing clean!
                </div>
              </div>
            )}

            <div className="demand-details">
              <FormGroup
                label="No laundry required"
                name="skip_laundry"
                type="checkbox"
                checked={values.skip_laundry}
              />
              <Explanation text="If checked - a laundry order will not be created." />
            </div>
            <FormGroup label="Notes" name="notes" type="text" />

            {!isEditMode && (
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  setShowCleanerSelect(true);
                }}
              >
                Add clean details
              </button>
            )}

            {showCleanerSelect && (
              <CleanerSelection
                propertyId={propertyId}
                selectedCleanerJSON={selectedCleanerJSON}
                localityId={localityId}
                fieldName="cleaner"
                handleCleanerIdChange={(value) => {
                  setFieldValue("cleaner_id", value);
                }}
              />
            )}
            <input
              type="submit"
              name="commit"
              value="Save"
              disabled={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};
