import { Checkbox, Group, NumberInput } from "@mantine/core";
import { DatesProvider, DateTimePicker } from "@mantine/dates";
import { format, startOfMinute } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";

import { MantineProvider } from "../../../common/MantineProvider";
import { formatHours, toLocalFromUTC } from "./dateTimeHelpers";

export const CleanStartDatePicker = ({
  start,
  same_day_booking_checkin,
  duration: initialDuration
}) => {
  const [localStart, setLocalStart] = useState(() => toLocalFromUTC(start));
  const [duration, setDuration] = useState(initialDuration);
  const [allowOverlap, setAllowOverlap] = useState(true);

  const localCheckin = useMemo(
    () =>
      same_day_booking_checkin
        ? toLocalFromUTC(same_day_booking_checkin)
        : null,
    [same_day_booking_checkin]
  );

  const localEnd = useMemo(
    () => new Date(localStart.getTime() + duration * 60000 * 60),
    [duration, localStart]
  );

  const overlap = useMemo(
    () => (localCheckin ? localEnd >= localCheckin : false),
    [localCheckin, localEnd]
  );

  const error = useMemo(() => {
    if (!overlap || allowOverlap) {
      return null;
    }

    return `Clean cannot end after checkin (${format(localCheckin, "HH:mm")})`;
  }, [allowOverlap, localCheckin, overlap]);

  // Reset allowOverlap if overlap is false
  useEffect(() => {
    if (!overlap && allowOverlap) {
      setAllowOverlap(false);
    }
  }, [allowOverlap, overlap]);

  // Change submit button state based on validation
  useEffect(() => {
    const submitButton = document.querySelector("#clean_form_submit");

    if (!submitButton) {
      return;
    }

    if (!error) {
      submitButton.removeAttribute("disabled");
      return;
    }

    submitButton.setAttribute("disabled", "disabled");
  }, [error]);

  return (
    <>
      <MantineProvider>
        <Group grow align="flex-start" my="xs">
          <DatesProvider settings={{ locale: "en-gb" }}>
            <DateTimePicker
              label="Start"
              value={localStart}
              onChange={(newDate) => {
                setLocalStart(startOfMinute(newDate));
              }}
              error={error}
              inputWrapperOrder={["label", "input", "description", "error"]}
              description={`End: ${format(localEnd, "HH:mm")}`}
              minDate={toLocalFromUTC(start)}
              maxDate={toLocalFromUTC(start)}
            />
          </DatesProvider>
          <NumberInput
            label="Duration"
            placeholder="Duration in hours"
            precision={2}
            min={0}
            value={duration}
            onChange={setDuration}
            inputWrapperOrder={["label", "input", "description", "error"]}
            description={`${formatHours(duration)}`}
          />
        </Group>

        {overlap && (
          <Group my="xs">
            <Checkbox
              label="Allow overlap"
              checked={allowOverlap}
              onChange={({ target }) => setAllowOverlap(target.checked)}
            />
          </Group>
        )}
      </MantineProvider>

      <input
        name="clean[start(1i)]"
        value={localStart.getFullYear()}
        hidden
        readOnly
      />
      <input
        name="clean[start(2i)]"
        value={localStart.getMonth() + 1}
        hidden
        readOnly
      />
      <input
        name="clean[start(3i)]"
        value={localStart.getDate()}
        hidden
        readOnly
      />
      <input
        name="clean[start(4i)]"
        value={localStart.getHours()}
        hidden
        readOnly
      />
      <input
        name="clean[start(5i)]"
        value={localStart.getMinutes()}
        hidden
        readOnly
      />

      <input name="clean[duration]" value={duration} hidden readOnly />
    </>
  );
};
