import { Fancybox } from "@fancyapps/ui";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

import { LoadingSpinner } from "../../../../common/LoadingSpinner";
import {
  AIRBNB_DROP_ZONE_SETTINGS,
  GUESTY_DROP_ZONE_SETTINGS
} from "../constants";
import {
  selectCurrentFolderName,
  selectLoadingFiles,
  selectSortedFiles
} from "../data/files/selectors";
import { FileListItem } from "./FileList/FileListItem";
import { UploadDropZoneContainer } from "./FileList/UploadDropZoneContainer";

const { config: fancyboxConfig } = window.AirbaseConstants.fancybox;
const {
  FOLDER_NAME_AIRBNB,
  FOLDER_NAME_GUESTY
} = window.AirbaseConstants.PropertyFile;

export const FileListContainer = () => {
  const loadingFiles = useSelector((state) => selectLoadingFiles(state));
  const files = useSelector((state) => selectSortedFiles(state));
  const folderName = useSelector((state) => selectCurrentFolderName(state));

  const handleGalleryInit = useCallback(
    (startIndex) => {
      const galleryItems = files.map((file) => ({
        src: file.image_url,
        caption: `${file.file_name}<br/>${file.notes || ""}`
      }));

      Fancybox.show(galleryItems, { ...fancyboxConfig, startIndex });
    },
    [files]
  );

  const uploadDropZoneProps = useMemo(() => {
    const settings = {
      [FOLDER_NAME_AIRBNB]: AIRBNB_DROP_ZONE_SETTINGS,
      [FOLDER_NAME_GUESTY]: GUESTY_DROP_ZONE_SETTINGS
    };

    return settings[folderName] || {};
  }, [folderName]);

  if (!folderName) {
    return (
      <div className="d-flex justify-content-around p-5 bg-light">
        <p className="mt-2">Please choose a folder to view files.</p>
      </div>
    );
  }

  return (
    <div className="tab-content">
      <div className="tab-pane active">
        {loadingFiles ? (
          <LoadingSpinner message="Loading files..." />
        ) : (
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          <UploadDropZoneContainer {...uploadDropZoneProps}>
            {files.map((file, i) => (
              <FileListItem
                key={file.id}
                file={file}
                handleGalleryInit={() => handleGalleryInit(i)}
              />
            ))}
          </UploadDropZoneContainer>
        )}
      </div>
    </div>
  );
};
