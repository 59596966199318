import { useFormikContext } from "formik";
import React, { useCallback, useContext, useEffect, useState } from "react";

import { Toggle } from "../../../common/forms/Toggle";
import { SwalActions, modal } from "../../../common/modals";
import warningImage from "../../../images/warning.svg";
import { HomeInfoContext } from "./HomeInfoContext";

export const ListingAirbnbPhotosSyncToggle = () => {
  const { setSubmitting, isSubmitting } = useFormikContext();
  const { property, updateHomeInfo } = useContext(HomeInfoContext);

  const {
    homeInfo: {
      property_attributes: { listing_photos_ready }
    }
  } = useContext(HomeInfoContext);
  const [isListingGalleryReady, setIsListingGalleryReady] = useState(
    listing_photos_ready
  );

  useEffect(() => {
    setIsListingGalleryReady(listing_photos_ready);
  }, [listing_photos_ready]);

  const updateListingPhotosReadyFlag = useCallback(
    async (newListingReady) =>
      updateHomeInfo(
        {
          property_attributes: { listing_photos_ready: newListingReady }
        },
        { setSubmitting }
      ),
    [setSubmitting, updateHomeInfo]
  );

  const confirmationPopUp = useCallback(
    () =>
      new Promise((res) => {
        const closeConfirmation = () => modal.close();
        const onConfirm = () => {
          res({ confirmed: true });
          closeConfirmation();
        };
        const url = `https://www.airbnb.com/rooms/${property.airbnb_id}/photos`;

        modal.fire({
          html: (
            <>
              <p>
                All current Airbnb photos will be overwritten with the property
                listing photos from Airbase.&nbsp;
                <a href={url} target="_blank" rel="noreferrer">
                  Check the current Airbnb listing photos for reference.
                </a>
              </p>
              <SwalActions
                confirmText="I've reviewed images"
                cancelText="Cancel"
                onCancel={closeConfirmation}
                onConfirm={onConfirm}
              />
            </>
          ),
          title: "Listing photos need to be reviewed",
          showConfirmButton: false,
          showCancelButton: false,
          animation: false,
          imageUrl: warningImage,
          imageWidth: 75,
          imageHeight: 75,
          imageAlt: "Warning image",
          width: 550
        });
      }),
    [property.airbnb_id]
  );

  const handleSyncToggle = useCallback(async () => {
    if (isListingGalleryReady) {
      await updateListingPhotosReadyFlag(false);
      return;
    }

    const { confirmed } = await confirmationPopUp();
    if (!confirmed) {
      return;
    }

    await updateListingPhotosReadyFlag(true);
  }, [confirmationPopUp, isListingGalleryReady, updateListingPhotosReadyFlag]);

  return (
    <div className="photos_sync_toggle">
      <Toggle
        id="areListingPhotosReady"
        label="Enable photos sync?"
        checked={isListingGalleryReady}
        disabled={isSubmitting}
        onChange={handleSyncToggle}
        changeOrder
      />
    </div>
  );
};
