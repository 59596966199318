import { useField } from "formik";
import React from "react";

export const CustomField = ({ name, as: Component, ...props }) => {
  const [input, meta, helpers] = useField({ name });

  return (
    <Component
      {...input}
      {...props}
      error={meta.error}
      onChange={(value) => helpers.setValue(value)}
    />
  );
};
