import { useFormikContext } from "formik";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { useSelector } from "react-redux";

import { Toggle } from "../../../common/forms/Toggle";
import { SwalActions, modal } from "../../../common/modals";
import AlertIcon from "../../../images/alert-circle.svg";
import warningImage from "../../../images/warning.svg";
import { countFilesByFolder } from "../PropertyFiles/data/files/selectors";
import FieldIcon from "./components/FieldIcon";
import { HomeInfoContext } from "./HomeInfoContext";

const NOT_ENOUGH_PHOTOS_ERROR =
  "Please upload at least 10 photos to enable the sync";

const { FOLDER_NAME_GUESTY } = window.AirbaseConstants.PropertyFile;

export const ListingMultiplatPhotosSyncToggle = () => {
  const { setSubmitting, isSubmitting } = useFormikContext();
  const { updateHomeInfo } = useContext(HomeInfoContext);
  const foldersCount = useSelector(countFilesByFolder);

  const {
    homeInfo: {
      property_attributes: { multiplat_photos_ready }
    }
  } = useContext(HomeInfoContext);
  const [isListingGalleryReady, setIsListingGalleryReady] = useState(
    multiplat_photos_ready
  );

  useEffect(() => {
    setIsListingGalleryReady(multiplat_photos_ready);
  }, [multiplat_photos_ready, setIsListingGalleryReady]);

  const hasRequiredAmount = useMemo(
    () => foldersCount[FOLDER_NAME_GUESTY] >= 10,
    [foldersCount]
  );

  const updateListingPhotosReadyFlag = useCallback(
    async (newListingReady) =>
      updateHomeInfo(
        {
          property_attributes: { multiplat_photos_ready: newListingReady }
        },
        { setSubmitting }
      ),
    [setSubmitting, updateHomeInfo]
  );

  const confirmationPopUp = useCallback(
    () =>
      new Promise((res) => {
        const closeConfirmation = () => modal.close();
        const onConfirm = () => {
          res({ confirmed: true });
          closeConfirmation();
        };

        modal.fire({
          html: (
            <>
              <p>
                All current Guesty photos will be overwritten with the property
                listing photos from Airbase.&nbsp;
              </p>
              <SwalActions
                confirmText="I've reviewed images"
                cancelText="Cancel"
                onCancel={closeConfirmation}
                onConfirm={onConfirm}
              />
            </>
          ),
          title: "Listing photos need to be reviewed",
          showConfirmButton: false,
          showCancelButton: false,
          animation: false,
          imageUrl: warningImage,
          imageWidth: 75,
          imageHeight: 75,
          imageAlt: "Warning image",
          width: 550
        });
      }),
    []
  );

  const handleSyncToggle = useCallback(async () => {
    if (isListingGalleryReady) {
      await updateListingPhotosReadyFlag(false);
      return;
    }

    const { confirmed } = await confirmationPopUp();
    if (!confirmed) {
      return;
    }

    await updateListingPhotosReadyFlag(true);
  }, [confirmationPopUp, isListingGalleryReady, updateListingPhotosReadyFlag]);

  return (
    <div className="photos_sync_toggle">
      <Toggle
        id="areListingPhotosReady"
        label={
          <div>
            Enable photos sync?
            {!hasRequiredAmount && (
              <FieldIcon
                icon={<img src={AlertIcon} alt={NOT_ENOUGH_PHOTOS_ERROR} />}
                tooltipText={NOT_ENOUGH_PHOTOS_ERROR}
              />
            )}
          </div>
        }
        checked={isListingGalleryReady}
        disabled={
          isSubmitting || (!hasRequiredAmount && !isListingGalleryReady)
        }
        onChange={handleSyncToggle}
        changeOrder
      />
    </div>
  );
};
