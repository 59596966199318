import { Formik } from "formik";
import React, { useContext, useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Provider } from "react-redux";

import { LoadingSpinner } from "../../../../common/LoadingSpinner";
import { useToastMonitor } from "../../../../common/useToastMonitor";
import { UploadDropZoneContainer } from "../../PropertyFiles/components/FileList/UploadDropZoneContainer";
import { GUESTY_DROP_ZONE_SETTINGS } from "../../PropertyFiles/constants";
import { store } from "../../PropertyFiles/data/store";
import { GuestySyncIcon } from "../components/FormGroup";
import { HomeInfoContext } from "../HomeInfoContext";
import { HomeInfoForm } from "../HomeInfoForm";
import { ListingMultiplatPhotosSyncToggle } from "../ListingMultiplatPhotosSyncToggle";
import { ListingPhotosContext } from "../ListingPhotosContext";
import { useFileGallery } from "../useFileGallery";
import { ListingPhoto } from "./ListingPhoto";

const { FOLDER_NAME_GUESTY } = window.AirbaseConstants.PropertyFile;

export const ListingGalleryGuesty = () => {
  return (
    <Provider store={store}>
      <DndProvider backend={HTML5Backend}>
        <ListingGalleryInternal />
      </DndProvider>
    </Provider>
  );
};

const ListingGalleryInternal = () => {
  useToastMonitor();
  const { property, homeInfo, updateHomeInfo } = useContext(HomeInfoContext);
  const { setNumberOfAirbnbPhotos } = useContext(ListingPhotosContext);
  const {
    loading,
    files,
    findFile,
    moveFile,
    handleGalleryInit,
    locallySortedFiles,
    submitNewOrder
  } = useFileGallery(FOLDER_NAME_GUESTY, property);

  // We need local state to handle sorting without launching requests on dragging
  useEffect(() => {
    setNumberOfAirbnbPhotos(files.length);
  }, [files, setNumberOfAirbnbPhotos]);

  if (loading || !files) {
    return <LoadingSpinner message="Loading files..." />;
  }

  return (
    <div className="onboarding-form listing-gallery">
      <Formik
        initialValues={homeInfo}
        enableReinitialize
        onSubmit={updateHomeInfo}
      >
        <HomeInfoForm sidebarName="Property">
          <h4 className="section-name">
            Guesty listing photos
            <GuestySyncIcon />
          </h4>
          <ListingMultiplatPhotosSyncToggle />
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <UploadDropZoneContainer {...GUESTY_DROP_ZONE_SETTINGS}>
            {locallySortedFiles.map((file, i) => (
              <ListingPhoto
                key={file.id}
                file={file}
                findFile={findFile}
                moveFile={moveFile}
                submitNewOrder={submitNewOrder}
                handleGalleryInit={handleGalleryInit(i)}
              />
            ))}
          </UploadDropZoneContainer>
        </HomeInfoForm>
      </Formik>
    </div>
  );
};
