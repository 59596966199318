import get from "lodash/get";

import AIRBNB_SYNC_REQUIRED_FIELDS from "./airbnbSyncRequiredFields";
import { fieldNames } from "./fieldNames";
import { ACCESSIBILITY_CATEGORIES } from "./fields/constants/amenityCategories";
import { GUESTY_SYNC_REQUIRED_FIELDS } from "./guestySyncRequiredFields";
import {
  HOME_INFO_SECTION_AMENITIES,
  HOME_INFO_SECTIONS_CONFIGURATION,
  HOME_INFO_SECTIONS_PROPERTY,
  INTEGRATION,
  SIDEBAR_PATHS
} from "./sections";

const redirect = (history, path) => {
  history.push(path);
};

export const handleNavConfirm = ({
  history,
  isModified,
  path,
  setIsModified,
  setSidebarDraft,
  sidebarDraft
}) => {
  const sidebarName = Object.entries(SIDEBAR_PATHS).find(([, paths]) =>
    paths.includes(path)
  )?.[0];

  const sidebarChange = sidebarDraft?.name
    ? sidebarName !== sidebarDraft.name
    : false;

  if (isModified || sidebarChange) {
    if (window.confirm("Leave the page without saving?")) {
      setIsModified(false);
      setSidebarDraft({});
      redirect(history, path);
    }
  } else {
    redirect(history, path);
  }
};

export const prepareAmenityValuesForBackend = (values, assets) => {
  const prepared = values
    .map((amenity) => {
      const pendingAsset = assets?.find(
        ({ amenityTypeId }) =>
          amenityTypeId === amenity.home_info_amenity_type_id
      )?.pendingAsset?.fields;

      if (amenity.available) {
        // amenity existed before and was edited
        if (amenity.home_info_id) {
          return {
            id: amenity.amenity_id,
            external_notes: amenity.external_notes,
            internal_notes: amenity.internal_notes,
            deleted_at: null,
            property_file_attributes: pendingAsset
              ? {
                  property_id: pendingAsset.propertyId,
                  file_name: pendingAsset.fileName,
                  file_size: pendingAsset.fileSize,
                  folder_name: pendingAsset.folderName,
                  content_type: pendingAsset.contentType,
                  s3_object_key: pendingAsset.key
                }
              : undefined
          };
        }
        // new amenity
        return {
          external_notes: amenity.external_notes,
          internal_notes: amenity.internal_notes,
          home_info_amenity_type_id: amenity.home_info_amenity_type_id,
          property_file_attributes: pendingAsset
            ? {
                property_id: pendingAsset.propertyId,
                file_name: pendingAsset.fileName,
                file_size: pendingAsset.fileSize,
                folder_name: pendingAsset.folderName,
                content_type: pendingAsset.contentType,
                s3_object_key: pendingAsset.key
              }
            : undefined
        };
      }

      // amenity existed but was set to unavailable
      if (amenity.home_info_id && amenity.deleted_at == null) {
        return {
          id: amenity.amenity_id,
          external_notes: amenity.external_notes,
          internal_notes: amenity.internal_notes,
          deleted_at: new Date(Date.now())
        };
      }

      return null;
    })
    .filter((el) => el !== null);

  return {
    home_info_amenities_attributes: prepared
  };
};

// values - currently viewed existing amenities
export const prepareAmenitiesData = (types, values) => {
  const ready = types.map((type) => {
    const elementOfType = values.find(
      (el) => el.home_info_amenity_type_id === type.id
    );

    const {
      id: home_info_amenity_type_id,
      name,
      key,
      photo_description
    } = type;

    if (elementOfType) {
      return {
        home_info_amenity_type_id,
        name,
        key,
        ...elementOfType,
        available: !elementOfType.deleted_at,
        expanded: false,
        amenity_id: elementOfType.id,
        photo_description
      };
    }

    return {
      home_info_amenity_type_id,
      name,
      key,
      available: false,
      expanded: false,
      photo_description
    };
  });

  return ready;
};

export const hasAirbnbRequiredFields = (
  numberOfPhotos = 0,
  fields,
  requiredFields = AIRBNB_SYNC_REQUIRED_FIELDS
) => {
  const allFields = Object.values(requiredFields).flat();

  const listingPhotosReady = fields?.property_attributes?.listing_photos_ready;

  return allFields.every((element) => {
    if (element === "listing_photos") {
      return !(listingPhotosReady && numberOfPhotos < 1);
    }

    const value = get(fields, element, null);

    if (typeof value === "string" || element === "beds_attributes") {
      return value.length > 0;
    }

    return typeof value !== "undefined" && value !== null;
  });
};

export const hasAccessibilityAmenitiesRejectedPhotos = (fields, path) => {
  const category = HOME_INFO_SECTION_AMENITIES.find(
    ({ path: categoryPath }) => categoryPath === path
  )?.category;

  if (!category && !ACCESSIBILITY_CATEGORIES.includes(category)) {
    return false;
  }

  const values = fields?.home_info_amenities_attributes?.[category] ?? [];

  const {
    ACCESSIBILITY_STATUS_REJECTED
  } = window.AirbaseConstants.HomeInfoAmenity;

  return values.some(
    ({ accessibility_amenity_status }) =>
      accessibility_amenity_status === ACCESSIBILITY_STATUS_REJECTED
  );
};

export const hasSyncErrors = (listingData, path) => {
  if (path !== INTEGRATION) {
    return false;
  }

  return Boolean(listingData?.sync_error);
};

export const hasErrors = (fields, airbnbListingData, guestyListingData, path) =>
  hasSyncErrors(airbnbListingData, path) ||
  hasSyncErrors(guestyListingData, path) ||
  hasAccessibilityAmenitiesRejectedPhotos(fields, path);

export const guestyMissingFields = (fields) => {
  const allSections = Object.entries(GUESTY_SYNC_REQUIRED_FIELDS);

  const sections = allSections.map(([section, requiredFields]) => {
    const missingFields = requiredFields.filter((field) => {
      const value = get(fields, field, null);

      if (typeof value === "string" || Array.isArray(value)) {
        return value.length === 0;
      }

      return typeof value === "undefined" || value === null;
    });

    const sectionName =
      HOME_INFO_SECTIONS_PROPERTY.find(({ path }) => path === section)?.label ??
      HOME_INFO_SECTIONS_CONFIGURATION.find(({ path }) => path === section)
        ?.label ??
      section;

    return {
      section: sectionName,
      fields: missingFields.map((field) => fieldNames[field] ?? field)
    };
  });

  return sections.filter((section) => section.fields.length > 0);
};

export const getChangedValues = (values, initialValues) => {
  return Object.entries(values).reduce((acc, [key, value]) => {
    const hasChanged = initialValues[key] !== value;

    if (hasChanged) {
      acc[key] = value;
    }

    return acc;
  }, {});
};
