import AIRBNB_SYNC_REQUIRED_FIELDS from "./airbnbSyncRequiredFields";

export const INTEGRATION = "property-listing-integration";
export const LOCATION = "property-location";
export const LISTING_PHOTOS = "property-listing-photos-airbnb";
export const LISTING_PHOTOS_GUESTY = "property-listing-photos-guesty";
export const ACCESS = "property-access";
export const CHECK_IN = "property-checkin";
export const NOISE = "property-noise";
export const OTHER_NOTES = "property-guest-property-notes";
export const DESCRIPTION = "property-description";
export const CHECKOUT_INSTRUCTIONS = "property-checkout-instructions";

export const SIZE = "configuration-size";
export const DOORS_AND_KEYS = "configuration-doors-and-keys";
export const BEDS = "configuration-beds";

export const WIFI = "operate-wi-fi";
export const MAINTENANCE = "operate-maintenance";
export const KITCHEN = "operate-kitchen";
export const EMERGENCY = "operate-emergency";
export const OPERATE_BOILER = "operate-boiler";

export const KITCHEN_AND_DINING = "amenities-kitchen-and-dining";
export const ENTERTAINMENT = "amenities-entertainment";
export const PARKING_AND_FACILITIES = "amenities-facilities";
export const BEDROOM_AND_LAUNDRY = "bedroom-and-laundry";
export const SAFETY = "amenities-safety";
export const BATHROOM = "amenities-bathroom";
export const INTERNET_AND_OFFICE = "amenities-internet-and-office";
export const FAMILY = "amenities-family";
export const HEATING_AND_COOLING = "amenities-heating-and-cooling";
export const AMENITIES_LOCATION = "amenities-location";
export const AMENITIES_OUTDOOR = "amenities-outdoor";
export const ACCESSIBILITY = "amenities-accessibility";
export const ACCESSIBILITY_BATHROOM = "amenities-accessibility-bathroom";
export const ACCESSIBILITY_EQUIPMENT = "amenities-accessibility-equipment";
export const SERVICES = "amenities-services";

export const LISTING_PREFERENCES = "platform-settings-listing-preferences";
export const AVAILABILITY_RULES = "platform-settings-availability-rules";

export const ITEM_LOCATION = "cleaning-iteam-location";
export const WASTE_AND_RECYCLING = "cleaning-waste-and-recycling";
export const SPECIAL_TASKS = "cleaning-special-tasks";

export const INTERNAL = "internal-host-notes";

export const HOME_INFO_SECTIONS_PROPERTY = [
  {
    path: LOCATION,
    label: "Location",
    airbnbSyncRequiredFields: AIRBNB_SYNC_REQUIRED_FIELDS.LOCATION
  },
  {
    path: INTEGRATION,
    label: "Integration",
    airbnbSyncRequiredFields: AIRBNB_SYNC_REQUIRED_FIELDS.SYNC
  },
  {
    path: LISTING_PHOTOS,
    label: "Airbnb photos",
    airbnbSyncRequiredFields: AIRBNB_SYNC_REQUIRED_FIELDS.LISTING_PHOTOS
  },
  {
    path: LISTING_PHOTOS_GUESTY,
    label: "Guesty photos"
  },
  {
    path: ACCESS,
    label: "Access",
    airbnbSyncRequiredFields: AIRBNB_SYNC_REQUIRED_FIELDS.ACCESS
  },
  {
    path: CHECK_IN,
    label: "Check-in"
  },
  {
    path: NOISE,
    label: "Noise"
  },
  {
    path: OTHER_NOTES,
    label: "Guest & Property Notes"
  },
  {
    path: DESCRIPTION,
    label: "Listing description",
    airbnbSyncRequiredFields: AIRBNB_SYNC_REQUIRED_FIELDS.DESCRIPTION
  },
  {
    path: CHECKOUT_INSTRUCTIONS,
    label: "Checkout Instructions"
  }
];

export const HOME_INFO_SECTIONS_CONFIGURATION = [
  {
    path: SIZE,
    label: "Size",
    airbnbSyncRequiredFields: AIRBNB_SYNC_REQUIRED_FIELDS.SIZE
  },
  {
    path: DOORS_AND_KEYS,
    label: "Doors & Keys"
  },
  {
    path: BEDS,
    label: "Beds",
    airbnbSyncRequiredFields: AIRBNB_SYNC_REQUIRED_FIELDS.BEDS
  }
];

export const HOME_INFO_SECTION_OPERATE = [
  {
    path: WIFI,
    label: "WiFi"
  },
  {
    path: MAINTENANCE,
    label: "Maintenance, Meters and Fuse"
  },
  {
    path: KITCHEN,
    label: "Kitchen"
  },
  {
    path: EMERGENCY,
    label: "Emergency"
  },
  {
    path: OPERATE_BOILER,
    label: "Boiler"
  }
];

export const HOME_INFO_SECTION_AMENITIES = [
  {
    path: KITCHEN_AND_DINING,
    label: "Kitchen And Dining"
  },
  {
    path: ENTERTAINMENT,
    label: "Entertainment and Office"
  },
  {
    path: PARKING_AND_FACILITIES,
    label: "Parking and Facilities"
  },
  {
    path: BEDROOM_AND_LAUNDRY,
    label: "Bedroom And Laundry"
  },
  {
    path: SAFETY,
    label: "Safety"
  },
  {
    path: BATHROOM,
    label: "Bathroom"
  },
  {
    path: INTERNET_AND_OFFICE,
    label: "Internet and Office"
  },
  {
    path: FAMILY,
    label: "Family"
  },
  {
    path: HEATING_AND_COOLING,
    label: "Heating and cooling"
  },
  {
    path: AMENITIES_LOCATION,
    label: "Property Location"
  },
  {
    path: AMENITIES_OUTDOOR,
    label: "Outdoor"
  },
  {
    path: ACCESSIBILITY,
    label: "Accessibility",
    category: "accessibility_getting_home"
  },
  {
    path: ACCESSIBILITY_BATHROOM,
    label: "Accessibility Bathroom",
    category: "accessibility_bathroom"
  },
  {
    path: ACCESSIBILITY_EQUIPMENT,
    label: "Accessibility Equipment",
    category: "accessibility_equipment"
  },
  {
    path: SERVICES,
    label: "Services"
  }
];

export const HOME_INFO_SECTION_PLATFORM_SETTINGS = [
  {
    path: AVAILABILITY_RULES,
    label: "Availability rules",
    airbnbSyncRequiredFields: AIRBNB_SYNC_REQUIRED_FIELDS.AVAILABILITY_RULES
  },
  {
    path: LISTING_PREFERENCES,
    label: "Listing preferences",
    airbnbSyncRequiredFields: AIRBNB_SYNC_REQUIRED_FIELDS.LISTING_PREFERENCES
  }
];

export const HOME_INFO_SECTION_CLEANING = [
  {
    path: ITEM_LOCATION,
    label: "Item Location"
  },
  {
    path: WASTE_AND_RECYCLING,
    label: "Waste & Recycling"
  },
  {
    path: SPECIAL_TASKS,
    label: "Special Tasks"
  }
];

export const HOME_INFO_SECTION_INTERNAL = [
  {
    path: INTERNAL,
    label: "Host Notes"
  }
];

export const SIDEBAR_NAMES = {
  PROPERTY: "Property",
  CONFIGURATION: "Configuration",
  OPERATE: "Operate",
  AMENITIES: "Amenities",
  PLATFORM_SETTINGS: "Platform settings",
  CLEANING: "Cleaning",
  INTERNAL: "Internal"
};

export const SIDEBAR_PATHS = {
  [SIDEBAR_NAMES.PROPERTY]: HOME_INFO_SECTIONS_PROPERTY.map((el) => el.path),
  [SIDEBAR_NAMES.CONFIGURATION]: HOME_INFO_SECTIONS_CONFIGURATION.map(
    (el) => el.path
  ),
  [SIDEBAR_NAMES.OPERATE]: HOME_INFO_SECTION_OPERATE.map((el) => el.path),
  [SIDEBAR_NAMES.AMENITIES]: HOME_INFO_SECTION_AMENITIES.map((el) => el.path),
  [SIDEBAR_NAMES.PLATFORM_SETTINGS]: HOME_INFO_SECTION_PLATFORM_SETTINGS.map(
    (el) => el.path
  ),
  [SIDEBAR_NAMES.CLEANING]: HOME_INFO_SECTION_CLEANING.map((el) => el.path),
  [SIDEBAR_NAMES.INTERNAL]: HOME_INFO_SECTION_INTERNAL.map((el) => el.path)
};

export const SECTIONS_ORDER = {
  [LOCATION]: {
    prevRoute: "/",
    nextRoute: INTEGRATION
  },
  [INTEGRATION]: {
    prevRoute: LOCATION,
    nextRoute: LISTING_PHOTOS
  },
  [LISTING_PHOTOS]: {
    prevRoute: INTEGRATION,
    nextRoute: LISTING_PHOTOS_GUESTY
  },
  [LISTING_PHOTOS_GUESTY]: {
    prevRoute: LISTING_PHOTOS,
    nextRoute: ACCESS
  },
  [ACCESS]: {
    prevRoute: LISTING_PHOTOS,
    nextRoute: CHECK_IN
  },
  [CHECK_IN]: {
    prevRoute: ACCESS,
    nextRoute: NOISE
  },
  [NOISE]: {
    prevRoute: CHECK_IN,
    nextRoute: OTHER_NOTES
  },
  [OTHER_NOTES]: {
    prevRoute: NOISE,
    nextRoute: DESCRIPTION
  },
  [DESCRIPTION]: {
    prevRoute: OTHER_NOTES,
    nextRoute: CHECKOUT_INSTRUCTIONS
  },
  [CHECKOUT_INSTRUCTIONS]: {
    prevRoute: DESCRIPTION,
    nextRoute: SIZE
  },
  [SIZE]: {
    prevRoute: CHECKOUT_INSTRUCTIONS,
    nextRoute: DOORS_AND_KEYS
  },
  [DOORS_AND_KEYS]: {
    prevRoute: SIZE,
    nextRoute: BEDS
  },
  [BEDS]: {
    prevRoute: DOORS_AND_KEYS,
    nextRoute: WIFI
  },
  [WIFI]: {
    prevRoute: BEDS,
    nextRoute: MAINTENANCE
  },
  [MAINTENANCE]: {
    prevRoute: WIFI,
    nextRoute: KITCHEN
  },
  [KITCHEN]: {
    prevRoute: MAINTENANCE,
    nextRoute: EMERGENCY
  },
  [EMERGENCY]: {
    prevRoute: KITCHEN,
    nextRoute: OPERATE_BOILER
  },
  [OPERATE_BOILER]: {
    prevRoute: EMERGENCY,
    nextRoute: KITCHEN_AND_DINING
  },
  [KITCHEN_AND_DINING]: {
    prevRoute: OPERATE_BOILER,
    nextRoute: ENTERTAINMENT
  },
  [ENTERTAINMENT]: {
    prevRoute: KITCHEN_AND_DINING,
    nextRoute: PARKING_AND_FACILITIES
  },
  [PARKING_AND_FACILITIES]: {
    prevRoute: ENTERTAINMENT,
    nextRoute: BEDROOM_AND_LAUNDRY
  },
  [BEDROOM_AND_LAUNDRY]: {
    prevRoute: PARKING_AND_FACILITIES,
    nextRoute: SAFETY
  },
  [SAFETY]: {
    prevRoute: BEDROOM_AND_LAUNDRY,
    nextRoute: BATHROOM
  },
  [BATHROOM]: {
    prevRoute: SAFETY,
    nextRoute: INTERNET_AND_OFFICE
  },
  [INTERNET_AND_OFFICE]: {
    prevRoute: BATHROOM,
    nextRoute: FAMILY
  },
  [FAMILY]: {
    prevRoute: INTERNET_AND_OFFICE,
    nextRoute: HEATING_AND_COOLING
  },
  [HEATING_AND_COOLING]: {
    prevRoute: FAMILY,
    nextRoute: AMENITIES_LOCATION
  },
  [AMENITIES_LOCATION]: {
    prevRoute: HEATING_AND_COOLING,
    nextRoute: AMENITIES_OUTDOOR
  },
  [AMENITIES_OUTDOOR]: {
    prevRoute: AMENITIES_LOCATION,
    nextRoute: ACCESSIBILITY
  },
  [ACCESSIBILITY]: {
    prevRoute: AMENITIES_OUTDOOR,
    nextRoute: ACCESSIBILITY_BATHROOM
  },
  [ACCESSIBILITY_BATHROOM]: {
    prevRoute: ACCESSIBILITY,
    nextRoute: ACCESSIBILITY_EQUIPMENT
  },
  [ACCESSIBILITY_EQUIPMENT]: {
    prevRoute: ACCESSIBILITY_BATHROOM,
    nextRoute: SERVICES
  },
  [SERVICES]: {
    prevRoute: ACCESSIBILITY_EQUIPMENT,
    nextRoute: AVAILABILITY_RULES
  },
  [AVAILABILITY_RULES]: {
    prevRoute: SERVICES,
    nextRoute: LISTING_PREFERENCES
  },
  [LISTING_PREFERENCES]: {
    prevRoute: AVAILABILITY_RULES,
    nextRoute: ITEM_LOCATION
  },
  [ITEM_LOCATION]: {
    prevRoute: LISTING_PREFERENCES,
    nextRoute: WASTE_AND_RECYCLING
  },
  [WASTE_AND_RECYCLING]: {
    prevRoute: ITEM_LOCATION,
    nextRoute: SPECIAL_TASKS
  },
  [SPECIAL_TASKS]: {
    prevRoute: WASTE_AND_RECYCLING,
    nextRoute: INTERNAL
  },
  [INTERNAL]: {
    prevRoute: SPECIAL_TASKS
  }
};
