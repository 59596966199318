import React, { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { fetchPropertyFiles } from "./api";

export const ListingPhotosContext = createContext({
  numberOfAirbnbPhotos: 0,
  setNumberOfAirbnbPhotos: () => {}
});

const { FOLDER_NAME_AIRBNB } = window.AirbaseConstants.PropertyFile;

export const ListingPhotosProvider = ({ children, propertyId }) => {
  const [numberOfAirbnbPhotos, setNumberOfAirbnbPhotos] = useState(0);
  const location = useLocation();

  useEffect(() => {
    fetchPropertyFiles(propertyId).then((res) => {
      if (res?.files) {
        const grouped = Object.groupBy(
          res.files,
          ({ folder_name }) => folder_name
        );

        setNumberOfAirbnbPhotos(grouped[FOLDER_NAME_AIRBNB]?.length || 0);
      }
    });
  }, [location, numberOfAirbnbPhotos, propertyId]);

  return (
    <ListingPhotosContext.Provider
      value={{
        numberOfAirbnbPhotos,
        setNumberOfAirbnbPhotos
      }}
    >
      {children}
    </ListingPhotosContext.Provider>
  );
};
