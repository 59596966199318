export const fieldNames = {
  "property_attributes.street": "Street",
  "property_attributes.city": "City",
  "property_attributes.postcode": "Postcode",
  "property_attributes.country": "Country",
  "host_preferences_attributes.multiplat_listed": "Guesty Listing Status",
  "property_attributes.accommodates": "Number of guests",
  "property_attributes.bathrooms": "Number of bathrooms",
  "property_attributes.bedrooms": "Number of bedrooms",
  listing_title: "Listing Title",
  listing_summary: "Listing Summary",
  beds_attributes: "Beds"
};
