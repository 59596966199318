import React from "react";
import ReactDOM from "react-dom";

import { Modal } from "./Modal";

export function showConfirmationModal({ message = "Are you sure?" } = {}) {
  const el = document.createElement("div");
  el.className = "twbs";

  document.querySelector("body").appendChild(el);

  const cleanup = () => {
    ReactDOM.unmountComponentAtNode(el);
    el.remove();
  };

  return new Promise((resolve) => {
    const handleConfirm = () => {
      resolve(true);
      cleanup();
    };

    const handleCancel = () => {
      resolve(false);
      cleanup();
    };

    ReactDOM.render(
      <Modal onClose={handleCancel}>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light"
            onClick={handleCancel}
          >
            Cancel
          </button>

          <button
            type="button"
            className="btn btn-submit"
            onClick={handleConfirm}
          >
            Ok
          </button>
        </Modal.Footer>
      </Modal>,
      el
    );
  });
}
