import {
  MantineProvider as MantineProviderCore,
  createEmotionCache
} from "@mantine/core";
import React from "react";
import classNamespacePlugin from "stylis-plugin-class-namespace";

import "./MantineProvider.scss";

const cache = createEmotionCache({
  key: "mantine",
  prepend: false,
  stylisPlugins: [classNamespacePlugin({ namespace: `div#mantine ` })]
});

/**
 * Use this function to get the portal element
 * for rendering modals, popovers and other components
 *
 * e.g.
 * ```
 * <DatePickerInput
 *   ...
 *   popoverProps={{
 *     withinPortal: true,
 *     portalProps: { target: getPortal() }
 *   }}
 * />
 * ```
 * e.g.
 * ```
 * <Modal
 *   ...
 *   target={getPortal()}
 * >
 *   <div />
 * </Modal>
 * ```
 */
export const getPortal = () => {
  const existingPortal = document.querySelector("#mantine.portal");

  if (existingPortal) {
    return existingPortal;
  }

  const newPortal = document.createElement("div");
  newPortal.setAttribute("class", "portal");
  newPortal.setAttribute("id", "mantine");
  document.body.appendChild(newPortal);

  return newPortal;
};

/**
 * Resets all the global styles for children components
 *
 * If you want to use custom css inside this wrapper, make sure to wrap styles
 * in `:global(#mantine)` selector
 *
 * e.g.
 * ```
 * :global(#mantine) {
 *  .my-custom-class {
 *   color: red;
 *  }
 */
export const MantineProvider = ({ children }) => {
  return (
    <div id="mantine">
      <MantineProviderCore
        emotionCache={cache}
        theme={{ primaryColor: "dark" }}
      >
        {children}
      </MantineProviderCore>
    </div>
  );
};
