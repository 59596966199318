import { useFormikContext } from "formik";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";

import { formatDate, formatTime } from "../../../common/date";
import { Toggle } from "../../../common/forms/Toggle";
import { SwalActions, modal } from "../../../common/modals";
import warningImage from "../../../images/warning.svg";
import { hasAirbnbRequiredFields } from "./helpers";
import { HomeInfoContext } from "./HomeInfoContext";
import { ListingPhotosContext } from "./ListingPhotosContext";

const Label = ({ date, hasRequiredFields }) => (
  <>
    Enable sync to Airbnb?
    <br />
    <span className="sync-date">
      {date ? (
        <>
          Last sync on {formatDate(new Date(date))} {formatTime(new Date(date))}
        </>
      ) : (
        "Never synced yet"
      )}
    </span>
    {hasRequiredFields ? (
      <span className="sync-required-fields-note">
        All required information is present
      </span>
    ) : (
      <span className="sync-required-fields-note error">
        Some of the required fields are missing
      </span>
    )}
  </>
);

export const HomeInfoFormSyncToggle = () => {
  const { setSubmitting, isSubmitting } = useFormikContext();
  const { homeInfo, updateHomeInfo } = useContext(HomeInfoContext);
  const { numberOfAirbnbPhotos } = useContext(ListingPhotosContext);

  const hasRequiredFields = useMemo(
    () => hasAirbnbRequiredFields(numberOfAirbnbPhotos, homeInfo),
    [homeInfo, numberOfAirbnbPhotos]
  );

  const isButtonDisabled = useMemo(() => isSubmitting || !hasRequiredFields, [
    isSubmitting,
    hasRequiredFields
  ]);

  const {
    homeInfo: {
      property_attributes: {
        listing_ready,
        airbnb_synced_at,
        listing_rejected_status_notes,
        listing_approval_status_approved
      }
    },
    airbnbListingData: { sync_error }
  } = useContext(HomeInfoContext);
  const [isListingReady, setIsListingReady] = useState(listing_ready);

  useEffect(() => {
    setIsListingReady(listing_ready);
  }, [listing_ready]);

  const updateListingFlag = useCallback(
    async (newListingReady) =>
      updateHomeInfo(
        {
          property_attributes: { listing_ready: newListingReady }
        },
        { setSubmitting }
      ),
    [setSubmitting, updateHomeInfo]
  );

  const confirmationPopUp = useCallback(
    () =>
      new Promise((res) => {
        const closeConfirmation = () => modal.close();
        const onConfirm = () => {
          res({ confirmed: true });
          closeConfirmation();
        };

        modal.fire({
          html: (
            <>
              <p>Are you sure you want to turn on sync for this property?</p>
              <SwalActions
                confirmText="Yes, all ready!"
                cancelText="No"
                onCancel={closeConfirmation}
                onConfirm={onConfirm}
              />
            </>
          ),
          showConfirmButton: false,
          showCancelButton: false,
          animation: false,
          imageUrl: warningImage,
          imageWidth: 75,
          imageHeight: 75,
          imageAlt: "Warning image"
        });
      }),
    []
  );

  const handleSyncToggle = useCallback(async () => {
    if (isListingReady) {
      await updateListingFlag(false);
      return;
    }

    const { confirmed } = await confirmationPopUp();
    if (!confirmed) {
      return;
    }

    await updateListingFlag(true);
  }, [confirmationPopUp, isListingReady, updateListingFlag]);

  const renderSyncErrorIcon = (content) => (
    <div className="field-icon">
      <i className="fas fa-exclamation-circle sync-error-icon" />{" "}
      <div className="field-icon__tooltip">{content}</div>
    </div>
  );

  const renderSyncErrorOrRejectionNotes = () => {
    if (sync_error) {
      const sync_error_obj = JSON.parse(sync_error);
      const { error_message } = sync_error_obj;
      return renderSyncErrorIcon(error_message);
    }

    if (!listing_approval_status_approved && listing_rejected_status_notes) {
      return renderSyncErrorIcon(listing_rejected_status_notes);
    }

    return null;
  };

  return (
    <div className="sync-toggle d-flex align-items-baseline">
      {renderSyncErrorOrRejectionNotes({
        listing_approval_status_approved,
        listing_rejected_status_notes,
        sync_error
      })}
      <Toggle
        id="isListingReady"
        label={
          <Label
            date={airbnb_synced_at}
            hasRequiredFields={hasRequiredFields}
          />
        }
        checked={isListingReady}
        disabled={isButtonDisabled}
        onChange={handleSyncToggle}
        containerClass="d-flex justify-content-between justify-content-md-start"
        changeOrder
      />
    </div>
  );
};
