import { useFormikContext } from "formik";
import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { handleNavConfirm } from "./helpers";
import { HomeInfoContext } from "./HomeInfoContext";
import { SECTIONS_ORDER } from "./sections";

export const HomeInfoFormButtons = () => {
  const {
    handleSubmit,
    isSubmitting,
    setFieldValue,
    isValid
  } = useFormikContext();

  const history = useHistory();
  const location = useLocation();
  const {
    isModified,
    setIsModified,
    sidebarDraft,
    setSidebarDraft
  } = useContext(HomeInfoContext);

  const currentPath = location.pathname.replace("/", "");

  const nextPath = SECTIONS_ORDER[currentPath].nextRoute;

  const handleSubmitAndContinue = (event) => {
    // A workaround for handling "Save & continue"
    setFieldValue(
      "__saveAndContinueHandler",
      nextPath ? () => history.push(nextPath) : () => history.push("/"),
      false
    );

    handleSubmit(event);
  };

  const onClick = () => {
    handleNavConfirm({
      sidebarDraft,
      setSidebarDraft,
      history,
      path: SECTIONS_ORDER[currentPath].prevRoute,
      isModified,
      setIsModified
    });
  };

  return (
    <div className="d-flex justify-content-between align-items-center w-100 row no-gutters">
      <div className="d-flex onboarding-form-buttons col">
        <button
          className="btn btn-light mr-md-2 mr-auto"
          type="button"
          onClick={onClick}
        >
          <i className="fa fa-caret-left" /> Back
        </button>

        <button
          className="btn btn-submit"
          type="submit"
          disabled={isSubmitting}
        >
          Save
        </button>

        <button
          className="btn btn-success"
          type="button"
          disabled={isSubmitting}
          onClick={handleSubmitAndContinue}
        >
          {nextPath ? (
            <>
              Save & Continue <i className="fa fa-caret-right" />
            </>
          ) : (
            "Save & Finish"
          )}
        </button>

        {!isValid && (
          <div
            className="text-danger ml-2"
            data-tooltip="This form contains errors"
          >
            <i className="fa fa-exclamation-circle" />
          </div>
        )}
      </div>
    </div>
  );
};
